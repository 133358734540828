import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import { myAssetsService } from '../../../shared/services/myassetservices';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { constants } from '../../../shared/services/constants';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,myAssetsService,LeavemanagementService,ValidationControllerFactory,LoginService,Router)
export class PendingAcknowledgement{
    CategoryId=constants.assetrequestworkflowcategoryid;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller, ProfileService,JwtService,myAssetsService,LeavemanagementService,ValidationControllerFactory,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.myAssetsService = myAssetsService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.loginservice = loginservice;
        this.router = router;

        ValidationRules
            .ensure('ToDate').displayName("Return on field is ").required()
            .on(this);
    }
    activate(viewData){
       // console.log(viewData);
        this.data = viewData;
        this.popupTitle=this.data.popupTitle;
        this.AssetRequisitionId=this.data.AssetRequisitionId;
        this.viewAsset=this.data.AssetStatus;
        this.status=this.data.status;
    }
    attached(){
        this.myAssetsService.GetAssetReturnForm(this.empid,this.AssetRequisitionId).then(response=>{
            this.assetlist=response ;
            this.AssetDetailID=this.assetlist.AssetDetailID,
                this.AssetAssignmentID=this.assetlist.AssetAssignmentID
        });
        this.leavemanagementService.GetAdditionalComments(this.AssetRequisitionId,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
            });

    }
    cancel(){
        this.controller.cancel();
    }


}
