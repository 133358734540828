import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import { myAssetsService } from '../../../shared/services/myassetservices';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { constants } from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,myAssetsService,LeavemanagementService,LoginService,Router)
export class ViewAssetList {
    isDragging = false;
    dialog;
    static inject = [Element];
    pageSize = 10;
    topicsPageSize=10;
    OnlineTopic = [];
    additionalcomments = [];
    CategoryId=constants.assetrequestworkflowcategoryid;
    constructor(controller, ProfileService,JwtService,myAssetsService,LeavemanagementService,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.myAssetsService = myAssetsService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.loginservice = loginservice;
        this.router = router;
    }
    activate(viewData){
        this.AssetRequisitionId = viewData;
        this.status=this.AssetRequisitionId.status;
    }
    attached(){
        this.myAssetsService.GetViewAssetRequisitionDetails(this.empid,this.AssetRequisitionId).then(data => {
            this.GetViewAsset = data;
            //console.log(data);
        });
        this.leavemanagementService.GetAdditionalComments(this.AssetRequisitionId,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
            });
    }
    cancel(){
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    };

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
