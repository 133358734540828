import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import { myAssetsService } from '../../../shared/services/myassetservices';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { constants } from '../../../shared/services/constants';
import {config} from "../../../shared/services/config";
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,myAssetsService,ValidationControllerFactory,LeavemanagementService,LoginService,Router)
export class WithDrawRequestedAssets {
    isDragging = false;
    dialog;
    static inject = [Element];
    pageSize = config.grid_PazeSize;
    topicsPageSize=config.grid_PazeSize;
    OnlineTopic = [];
    additionalcomments = [];
    CategoryId=constants.assetrequestworkflowcategoryid;
    constructor(controller, ProfileService,JwtService,myAssetsService,ValidationControllerFactory,LeavemanagementService,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.myAssetsService = myAssetsService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.loginservice = loginservice;
        this.router = router;
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        ValidationRules
            .ensure('Reason').required().withMessage('Comments are required.')
            .on(this);
    }
    attached(){
        this.myAssetsService.GetAssetRequisitionDetails(this.AssetRequisitionId).then(data => {
            this.RequisitionDetails = data;
            this.RequisitionDetailIND=this.RequisitionDetails.RequiredPeriodIND;
        });
        this.leavemanagementService.GetAdditionalComments(this.AssetRequisitionId,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
            });
    }
    activate(viewData){
        this.AssetRequisitionId = viewData;
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    downloadFile(DocumentPath) {
            const downloadfile = config.img_url +DocumentPath;
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentName;
            link.target = '_blank';
            link.click();
    }
    cancel(){
        this.controller.cancel();
    }
    submit(){
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
       const data= {
           EmployeeID: this.empid,
           Comments: this.Reason,
           AssetRequisitionID: this.AssetRequisitionId,
           DocumentName: this.DocumentName,
           DocumentPath: this.file,
       };
        this.myAssetsService.WithdrawnAsset(data).then(() => {
            this.controller.ok(true);
        });}
            else{
                    const errorElement = document.querySelector(".error-messages");
                    if (errorElement) {
                        errorElement.scrollIntoView();
                    }
                }
            });
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    };

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
