import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import { myAssetsService } from '../../../shared/services/myassetservices';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { constants } from '../../../shared/services/constants';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,myAssetsService,LeavemanagementService,ValidationControllerFactory,LoginService,Router)
export class CurrentAssetList{
    CategoryId=constants.assetrequestworkflowcategoryid;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller, ProfileService,JwtService,myAssetsService,LeavemanagementService,ValidationControllerFactory,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.myAssetsService = myAssetsService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.loginservice = loginservice;
        this.router = router;

        ValidationRules
            .ensure('ToDate').displayName("Returned on field ").required()
            .on(this);
    }
    activate(viewData){
        this.data = viewData;
        this.status=this.data.status;
        this.AssetRequisitionId=this.data.AssetRequisitionId;
        this.viewAsset=this.data.AssetStatus;
      //  console.log(viewData);
    }
    attached(){
        flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate:  null,
            maxDate: new Date(),
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.default = dateStr;
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });
        this.myAssetsService.GetAssetReturnForm(this.empid,this.AssetRequisitionId).then(response=>{
            this.assetlist=response ;
            this.AssetDetailID=this.assetlist.AssetDetailID,
                this.AssetAssignmentID=this.assetlist.AssetAssignmentID
            //console.log(response);
        });
        this.leavemanagementService.GetAdditionalComments(this.AssetRequisitionId,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
            });

    }
    cancel(){
        this.controller.cancel();
    }
    submit(){
        if(this.status =="ISS")
        {
            let Acknowledgementasset = {
                EmployeeID:this.empid,
                AssetRequisitionID:this.AssetRequisitionId,
                AssetDetailsID:this.AssetDetailID,
                AssetAssignmentID:this.AssetAssignmentID,
            };
            this.myAssetsService.Acknowledgementasset(Acknowledgementasset).then(() => {
                this.controller.ok(true);
                });
        }
        if(this.status =="ASG")
        {
            this.controllerFactory.validate()
                .then(result => {
                    if (result.valid) {
                        let returnasset = {
                            EmployeeID:this.empid,
                            AssetRequisitionID:this.AssetRequisitionId,
                            ReasonforReturn:this.Comment,
                            ReturnedQty:this.IssuedQty,
                            ReturnedOn:this.ToDate,
                            AssetDetailsID:this.AssetDetailID,
                            AssetAssignmentID:this.AssetAssignmentID,
                        };
                        this.myAssetsService.Returnasset(returnasset).then(() => {
                            this.controller.ok(true);
                        });
                    }
                });
        }
    }

}
