import {inject} from 'aurelia-framework';
import { myAssetsService } from '../../../shared/services/myassetservices';
import {ValidationControllerFactory, ValidationRules,validateTrigger} from 'aurelia-validation';
import * as tinymce from 'tinymce';
import {Router} from 'aurelia-router';
import { App } from '../../../app';
import {DialogService} from 'aurelia-dialog';
import { JwtService } from '../../../shared/services/jwtservice';
import flatpickr from "flatpickr";
import { LeavemanagementService } from '../../../shared/services/leavemanagementservice';
import * as XLSX from "xlsx";
import {WithDrawRequestedAssets} from "./../withdrawrequestedassets/withdrawrequestedassets";
import {ViewMyCurrentAssetsrequest} from "./../viewmycurrentassetsrequest/viewmycurrentassetsrequest";
import {config} from "../../../shared/services/config";
import {constants} from "../../../shared/services/constants";
import {CurrentAssetList} from "./../currentassetlist/currentassetlist";
import {ViewAssetList} from "./../viewassetlist/viewassetlist";
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {PendingAcknowledgement} from '../viewcurrentassetlist/pendingAcknowledgement'

@inject(myAssetsService, ValidationControllerFactory,DialogService ,JwtService, Router, App,LeavemanagementService,LoginService)
export class MyassetComponent {
    selectedValue = "2";
    pageSize = config.assetgrid_PazeSize;
    pageSize1 = config.assetgrid_PazeSize;
    FavoritesExist = false;
    differenceError = false;
    showSuccessMessage = false;
    sitemapid=constants.assetsitemapid;
    assetrequestionlist=constants.assetrequestionlist;
    WithdrawnSuccessmessages=false;
    AcknowledgementSuccessmessage=false;
    ErrorMessageShow=false;
    FavoritesExist1 = false;
    showToDateValidation = false;
    isButtonDisabled = false;
    dropdown=null;
    constructor(myAssetsService, controllerFactory, dialogService,JwtService, router, App,LeavemanagementService,loginservice) {
        this.myAssetsService = myAssetsService;
        this.jwtService = JwtService;
        this.controller = controllerFactory.createForCurrentScope();
        this.dialogService = dialogService;
        this.router = router;
        this.empid = this.jwtService.getEmployeeId();
        this.locid=this.jwtService.getLocationId();
        this.leavemanagementService = LeavemanagementService;
        this.controller.validateTrigger = validateTrigger.change;
        this.loginservice = loginservice;
        ValidationRules.customRule(
            'dateError',
            () =>{
                if(this.differenceError === true){
                    return false;
                }else{
                    return true;
                }
            },
            '${$displayName} by should be less than required till.'
        );
        ValidationRules
            .ensure('ToDate').displayName("Required").satisfiesRule('dateError')
            .ensure('dropdown').displayName("Required asset field").required()
            .ensure('quanity').displayName("Requested quantity field").required()
            .ensure('FromDate').displayName("Required by field").required()
            .ensure('reason').displayName("Reason field").required()
            .on(this);
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.asset');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.asset .sidebar-sub .myassetdetails a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached(){
        this.getCurrentRouteURL();
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
            Scrollbar.init(document.querySelector('.requested-asset-table'));
        }
        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: new Date(),
            maxDate: null,
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.FromDate = dateStr;
            }
        });
        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });
        flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate:  new Date(),
            maxDate: null,
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.default = dateStr;
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });
        this.myAssetsService.GetMyAssetList(this.empid).then(data => {
            this.MyAssetList = data;
        });
        this.myAssetsService.GetMyAssetRequisitionList(this.empid).then(data => {
            this.MyAssetRequisitionList = data;
        });
        this.myAssetsService.GetAssetForDropDown().then(data => {
            this.MyAssetDropDown = data;
        });
        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapid)
            .then(data=>{
                this.FavoritesExist=data;
            });
        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.assetrequestionlist)
            .then(data=>{
                this.FavoritesExist1=data;
            });

        this.addToRecentItems();

    }
    updateSelectedValue(event) {
        this.selectedValue = event.target.value;
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);

            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
    addToFavouritesCurrentAssest(){
        this.leavemanagementService.AddToFavourites(this.empid,this.sitemapid).then(()=>
        {
            //this.attached();
            window.location.reload();
        });
    }
    addToFavouritesRequestedAsset(){
        this.leavemanagementService.AddToFavourites(this.empid,this.assetrequestionlist).then(()=>
        {
            // this.attached();
            window.location.reload();
        });
    }
    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };
        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }
    MyRequestedAssetsActionDropdown(AssetRequisitionId) {
        this.dialogService.open({viewModel: WithDrawRequestedAssets, model: AssetRequisitionId})
            .whenClosed((feedback) => {
                if (!feedback.wasCancelled) {
                    this.myAssetsService.GetMyAssetRequisitionList(this.empid).then(data => {
                        this.MyAssetRequisitionList = data;
                        this.Withdrawnmessages = "Asset withdrawn successfully.";
                        this.WithdrawnSuccessmessages = true;
                        setTimeout(() => {
                            this.messages = null
                        }, 5000);
                    });
                }
            });
    }
    MyRequestedAssetsViewDropdown(AssetRequisitionId) {
        this.dialogService.open({viewModel: ViewMyCurrentAssetsrequest, model: AssetRequisitionId});
    }
    CurrentAssetsActionDialog(AssetRequisitionId,status) {
        this.openPopupWithCustomTitle(AssetRequisitionId, status, "ARF")

    }
    CurrentAssetsViewDialog(AssetRequisitionId,status,AssetStatus) {
        if (status=="RET" || status=="AVL") {
            this.dialogService.open({
                viewModel: ViewAssetList,
                model:  AssetRequisitionId
            })
        }else {
            this.dialogService.open({
                viewModel: PendingAcknowledgement,
                model: { AssetRequisitionId, status,AssetStatus }
            })

        }
    }
    openPopupWithCustomTitle(AssetRequisitionId, status,AssetStatus) {
        this.dialogService.open({
            viewModel: CurrentAssetList,
            model: { AssetRequisitionId, status,AssetStatus }
        }).whenClosed((feedback) => {
            console.log(AssetRequisitionId, status,AssetStatus);
            if (!feedback.wasCancelled) {
                this.myAssetsService.GetMyAssetList(this.empid).then(data => {
                    this.MyAssetList = data;
                    console.log(data);
                    if (status=='ISS'){
                            this.AcknowledgementSuccessmessage = "Asset received successfully.";
                    this.AcknowledgementSuccess = true;
                        setTimeout(() => {
                            this.AcknowledgementSuccess=null;
                        }, 5000);
                    }
                    if (status =='ASG') {
                        this.ReturnedSuccessmessage = "Asset returned successfully.";
                        this.ReturnSuccess = true;
                        setTimeout(() => {
                            this.ReturnSuccess=null;
                        }, 5000);
    
                    }
    
    
                });
            }
        });
    }
    calculateDateDifference() {
        if (this.FromDate && this.ToDate) {
            const fromParts = this.FromDate.split('-');
            const toParts = this.ToDate.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            if (fromDate >= toDate) {
                this.differenceError = true;
                //this.showToDateValidation=true;
            }
            else{
                this.differenceError = false;
                this.showToDateValidation=false;
            }
        }
    }
    exportData() {
        const data = this.MyAssetList;
        const filename = 'Mycurrentassets';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const requiredColumns = ['Asset', 'Asset name', 'Request date', 'Status'];
        const filteredData = data.map(timeoff => {
            return {
                'Asset': timeoff.Name,
                'Asset name': timeoff.AssetName,
                'Request date': timeoff.RequestDate,
                'Status': timeoff.StatusText,
            };
        });
        const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: requiredColumns });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    XLData() {
        const dataxl = this.MyAssetRequisitionList;
        const filenames = 'Myrequestedassets';
        this.exportToExcel1(dataxl, filenames);
    }
    // exportToExcelData(datas, filenames) {
    //     const Columns = ['Requisitionno', 'Asset', 'Requestdate', 'Requestedqty','Status'];
    //     const filteredXlData = datas.map(value => {
    //         return {
    //             Requisitionno: value.AssetRequisitionId,
    //             Asset: value.Name,
    //             Requestdate: value.RequestDate,
    //             Requestedqty: value.RequestedQty,
    //             Status: value.StatusText,
    //         };
    //     });
    //     const worksheets = XLSX.utils.json_to_sheet(filteredXlData, { header: Columns });
    //     const workbooks = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbooks, worksheets, 'Sheet 1');
    //     XLSX.writeFile(workbooks, `${filenames}.xlsx`);
    // }
    exportToExcel1(dataxl, filename) {
        const requiredColumns = ['Requisition no', 'Asset', 'Request date','Requested qty','Status'];
        const filteredData = dataxl.map(o => {
        return {
                        'Requisition no': o.AssetRequisitionId,
                        'Asset': o.Name,
                        'Request date': o.RequestDate,
                        'Requested qty': o.RequestedQty,
                        'Status': o.StatusText,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    resetForm(){
        this.controller.reset();
        this.assetdetails='';
        this.FromDate='';
        this.quanity ='';
        this.ToDate='';
        this.reason='';
        this.empid='';
        this.DocumentName='';
        this.file='';
        this.dropdown='';
        this.Comment='';
    }
    validateField(data){
        if(data)
        {
            this.controller.clear();

        }
        ValidationRules
            .ensure('ToDate').displayName("Required").satisfiesRule('dateError')
            .ensure('dropdown').displayName("Required asset field").required()
            .ensure('quanity').displayName("Requested quantity field").required()
            .ensure('FromDate').displayName("Required by field").required()
            .ensure('reason').displayName("Reason field").required()
            .on(this.controller);
    }
    submitForm() {
        event.preventDefault();
        this.controller.validate()
            .then(result => {
                if (result.valid) {
                    if (this.selectedValue === '1' && !this.ToDate) {
                        this.showToDateValidation = true;
                    } else {
                        this.showToDateValidation = false;
                        let assetrequest = {
                            AssetCategoryID: this.dropdown,
                            Description: this.assetdetails,
                            Requiredby: this.FromDate,
                            RequestedQunatity: this.quanity,
                            RequiredPeriodIND: this.selectedValue,
                            RequiredTillDate: this.ToDate,
                            ReasonforRequest: this.reason,
                            // ReasonforReturn: this.reasonss,
                            EmployeeId: this.empid,
                            DocumentName: this.DocumentName,
                            DocumentPath: this.file,
                            Comments: this.Comment,
                        };
                        this.myAssetsService.IsAssetWorkflowApproverExist(this.locid, this.dropdown).then(data => {
                            if (data == true) {
                                this.myAssetsService.AddAssetRequisition(assetrequest).then(data => {
                                    this.ErrorMessageShow = false;
                                    this.showSuccessMessage = true;
                                    setTimeout(() => {
                                        this.showSuccessMessage = null
                                    }, 5000);
                                    const errorElement = document.querySelector(".scrolltoerrorsuccess");
                                    if (errorElement){
                                        errorElement.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
                                        window.scrollBy(0, -200);
                                    }
                                    this.myAssetsService.GetMyAssetRequisitionList(this.empid).then(data => {
                                        this.MyAssetRequisitionList = data;
                                    });
                                    this.assetdetails = '';
                                    this.FromDate = '';
                                    this.quanity = '';
                                    this.selectedValue = '2';
                                    this.ToDate = '';
                                    this.reason = '';
                                    this.empid = '';
                                    this.DocumentName = '';
                                    this.file = '';
                                    this.dropdown = '';
                                    this.Comment = '';
                                });
                            } else {
                                this.ErrorMessageShow = true;
                            }
                        });
                    }
                }else{
                    const errorElement = document.querySelector(".scrolltoerrorsuccess");
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
                        window.scrollBy(0, -200);
                    }
                }
            });
    }
    addToRecentItems(){
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapid).then(data=>
        {
            // window.location.reload();

        });
    }
}
